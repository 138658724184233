import './header.component.scss';

export function Header() {
  return (
    <>
      <nav className="p-3 bg-header-secondary d-flex">
        <div className="container justify-content">
          <div>
            <img src="images/Header.png" alt="Even Anders Header" />
          </div>
        </div>
      </nav>
    </>
  );
}
