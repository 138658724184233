import './footer.component.scss';

export function Footer() {
  return (
    <>
      <footer className="p-3 bg-footer-secondary d-flex sticky-bottom">
        <div className="container justify-content">
          <div className=" py-3">
            <h1 className="text-white">Marktplein 32, 3590 Diepenbeek</h1>
          </div>
        </div>
      </footer>
    </>
  );
}
