import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.scss';
import { Footer, Header } from './components';

function App() {
  return (
    <>
      <Header />
      <main>
        <div className="container-outlet">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default App;
