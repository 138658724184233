export function OpeningHours() {
  return (
    <>
      <h4 className="fw-semibold mb-0 text-bg-title fs-4 rounded-3 ">
        Openingsuren
      </h4>
      <div className="d-flex">
        <div className="me-auto">
          Maandag
          <br />
          Dinsdag
          <br />
          Woensdag
          <br />
          Donderdag
          <br />
          Vrijdag
          <br />
          Zaterdag
          <br />
          Zondag
        </div>
        <div className="justify-content-right">
          GESLOTEN
          <br />
          9u - 18u
          <br />
          9u - 18u
          <br />
          9u - 18u
          <br />
          9u - 18u
          <br />
          9u - 18u
          <br />
          9u - 13u
        </div>
      </div>
      <div>
        <i>De tijden kunnen veranderen</i>
      </div>
    </>
  );
}
